window.__tnt || (window.__tnt = {});
window.__tnt.pay || (window.__tnt.pay = {});
window.__tnt.pay.method || (window.__tnt.pay.method = {});

// init - runs at form init
// submitPayment - runs when the user clicks the pay button (if this is selected)

__tnt.pay.method.wallet = {
    name: "wallet",
    methodString: "Credit Card",
    init: function(pf) {
        var payMethodSelect = pf.querySelector('.tn-pay-method-select'),
            payMethodSelectOutput = null,
            payMethodSelectNewCard = null;

        if (__tnt.user && __tnt.user.authToken) {
            var walletPromise = new Promise(function (resolve, reject) {
                var walletResolve = resolve,
                    walletReject = reject;

                pf.FormInitPromises.push(fetch('/tncms/pay/wallet/list/', {
                    method: 'get',
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                }).then(function(oResp){
                    if(oResp.ok){ oResp.json().then(function(oData){
                        var payMethodSelectWrapper = pf.querySelector('.tn-pay-method-select'),
                            templateEl = null,
                            outputEl = null,
                            iconsEl = document.getElementById('tn-pay-card-templates'),
                            expiringNotification = null,
                            expiredNotification = null,
                            dateCheck = new Date(),
                            filteredItems = [],
                            uniqueItems = [],
                            limitedItems = [],
                            templatePromises = [];

                        if (payMethodSelectWrapper && oData && oData.items && oData.items.length) {
                            templateEl = payMethodSelectWrapper.querySelector('template.wallet-template');
                            outputEl = payMethodSelectWrapper.querySelector('.tn-pay-method-output');
                            expiringNotification = payMethodSelectWrapper.querySelector('.wallet-expiring-notification');
                            expiredNotification = payMethodSelectWrapper.querySelector('.wallet-expired-notification');

                            if (outputEl) { outputEl.innerHTML = "" }

                            // @TODO - all this filtering code can hopefully be removed in the future
                            // Sort Items by Expiration Date in descending order (card furthest in the future will be first)
                            oData.items.sort(function(itemA, itemB){
                                if ((itemA.year < itemB.year) || ((itemA.year == itemB.year) && (itemA.month < itemB.month))) { return 1 }

                                return -1
                            });

                            // If a renewal token exists, sort the matching item to be the first option presented
                            oData.items.sort(function(itemA, itemB){
                                if (itemA.id != "" && itemB.id == "") { return 1 }
                                if (itemA.id == "" && itemB.id != "") { return -1 }

                                return 1;
                            });
                            if (oData.items[0] && oData.items[0].type) {
                                renewalPaymentMethodType = oData.items[0].type;
                            }

                            // filter out cards with expired dates
                            filteredItems = oData.items.filter(function(item){
                                if((item.year > dateCheck.getUTCFullYear()) || ((item.year == dateCheck.getUTCFullYear()) && (item.month > dateCheck.getUTCMonth()))){ return true }

                                return false;
                            });

                            // filter out cards with the same card_type and last four
                            filteredItems = filteredItems.filter(function(item){
                                if(uniqueItems.indexOf(item.last_four_digits) < 0){ uniqueItems.push(item.last_four_digits); return true; }

                                return false;
                            });

                            // limit to the most recent - pass this variable somehow
                            limitedItems = filteredItems.slice(0,3);

                            if(limitedItems.length){
                                limitedItems.forEach(function(item){
                                    var cardType = "credit card",
                                        cardIcon = null,
                                        cardIconEl = null,
                                        cardLabel = "Credit Card",
                                        expClass = "tn-cc-exp text-muted";

                                    if(item.card_type && iconsEl){
                                        cardIconEl = iconsEl.querySelector('template[data-card-spreedly="' + item.card_type + '"]');

                                        if(cardIconEl){
                                            cardLabel = cardIconEl.dataset.card;
                                            cardIcon = cardIconEl.content.cloneNode(true);
                                        }

                                    } else if (payMethodCardTemplates) {
                                        cardIconEl = iconsEl.querySelector(".other");

                                        if(cardIconEl && cardIconEl.content){
                                            cardIcon = cardIconEl.content.cloneNode(true);
                                        }
                                    }

                                    if(item.year == dateCheck.getUTCFullYear() && item.month <= (dateCheck.getUTCMonth()+1)){
                                        expClass = "tn-cc-exp text-warning"
                                    }

                                    var elements = [
                                        {
                                            'element' : 'a.btn',
                                            'attr' : [
                                                {
                                                    'attr': 'data-token',
                                                    'value': JSON.stringify(item)
                                                }
                                            ]
                                        },{
                                            'element' : '.tn-cc-label',
                                            'html' : cardLabel
                                        }, {
                                            'element' : '.tn-cc-last-four',
                                            'html' : item.last_four_digits
                                        }, {
                                            'element' : '.tn-cc-exp',
                                            'attr' : [
                                                {
                                                    'attr' : 'class',
                                                    'value' : expClass
                                                }
                                            ],
                                            'html' : " Exp " + item.month + "/" + item.year
                                        }
                                    ];
        
                                    var templatePromise = new Promise(function(resolve, reject){
                                        __tnt.template(
                                            templateEl,    // template element
                                            outputEl,      // output element
                                            false,         // add items from start of output
                                            elements,      // elements in template to modify
                                            function (t) { // callback to run after template is added
                                                if (!payMethodSelect.classList.contains('tn-pay-method-found')) {
                                                    payMethodSelect.classList.add('tn-pay-method-found');
                                                }
    
                                                var link = t.querySelector('a'),
                                                    cardIconSVG = null;
    
                                                if(cardIcon){
                                                    cardIconSVG = document.importNode(cardIcon, true);
                                                    t.querySelector('a .tn-cc-lg').appendChild(cardIconSVG);
                                                }
    
                                                // In renewal mode highlight the previously used payment method
                                                if(item.id==""){ // get the renewal id somehow
                                                    if(expiringNotification && item.year == dateCheck.getUTCFullYear() && item.month <= dateCheck.getUTCMonth()+1){
                                                        expiringNotification.hidden = false;
                                                        expiringNotification.classList.remove('fade');
                                                    }
                                                    if(link){ link.classList.add('tn-pay-method-selected') }
                                                }
                
                                                t.querySelector('a').addEventListener('click',function(ev){
                                                    var token = null;

                                                    try {
                                                        if(this.dataset && this.dataset.token){
                                                            token = JSON.parse(this.dataset.token);
                                                        }
                                                    } catch(e){ }

                                                    var addNewCardEl = pf.querySelector('.tn-pay-method-new-card'),
                                                        cardEntryEl = pf.querySelector('.tn-pay-card-entry');

                                                    cardEntryEl.hidden = false;

                                                    __tnt.pay.setPaymentMethod(pf, "wallet", this, token);
                                                });

                                                resolve({ complete: true });
                                            }
                                        );
                                    }); // template promise
                                    
                                    templatePromises.push(templatePromise);
                                }); // end of items loop

                                Promise.all(templatePromises).then(function(){
                                    walletResolve({ complete: true });
                                });
    
                            } else { walletResolve({ complete: true }) } // no items left after filter
                            
                        } else { walletResolve({ complete: true }) } // no items found
            
                    }); } // end of data
                }) ); // end fetch wrapper promise

            });

            pf.FormInitPromises.push(walletPromise);
        } // user check
    }, // end init
    submitPayment: function(pf) {
        if(pf && pf.dataset.spreedlyContext){
            var Spreedly = window[pf.dataset.spreedlyContext];

            Spreedly.recache();
        }
    }
}

